import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import {
    View,
    Image,
    ListRenderItemInfo,
    ScrollView,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';
import { gql } from 'graphql-request';
import useGraphQLClient from '../../../../graphql';
import { Modalize } from 'react-native-modalize';
import {
    Button,
    HelperText,
    List,
    Portal,
    Modal,
    TextInput,
    Text,
    Surface,
    useTheme,
    IconButton,
    RadioButton,
    Chip,
} from 'react-native-paper';
import { FormikHelpers, useFormik } from 'formik';
import { find, transform, has, omit, mapValues, get, pickBy } from 'lodash';
import { styled } from 'nativewind';
import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker';
import {
    requestForegroundPermissionsAsync,
    getCurrentPositionAsync,
} from 'expo-location';
import { FlatList } from 'react-native-gesture-handler';
import fetch from 'cross-fetch';
import * as Yup from 'yup';
import { Country } from '../../../../models/Country';
import { Region } from '../../../../models/Region';
import { Province } from '../../../../models/Province';
import { City } from '../../../../models/City';
import { Suburb } from '../../../../models/Suburb';
import { useTranslation } from 'react-i18next';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
    useCategoriesQuery,
    useCitiesQuery,
    useCountriesQuery,
    useProvincesQuery,
    useRegionsQuery,
    useRetailerQuery,
    useSuburbsQuery,
} from '../../../../graphql/hooks';
import WorkingHours from '../../../UI/organisms/WorkingHours';
import MapComponent from '../../../UI/organisms/Map';
import tailwindColors from 'tailwindcss/colors';

interface Point {
    lat: number;
    lng: number;
}

interface Values<T> {
    name: string;
    secondary_name: string;
    country: string;
    region: string;
    province: string;
    city: string;
    suburb: string;
    location: Point | null;
    zipcode: string;
    email: string;
    address1: string;
    address2: string;
    address3: string;
    notes: string;
    whatsapp: string;
    phone_number: string;
    owns_smartphone: boolean;
    categories: number[];
    images: T[];
    working_hours: any;
}

interface Props {}

const useUpdateRetailerMutation = (id: string) => {
    const client = useGraphQLClient();

    return useMutation(async (data: Values<File>): Promise<string> => {
        const {
            updateRetailer: { id: updatedId },
        } = await client.request(
            gql`
                mutation ($data: UpdateRetailerInput!) {
                    updateRetailer(data: $data) {
                        id
                    }
                }
            `,
            { data: { id, ...data } }
        );

        return updatedId;
    });
};

const useRemoveImageMutation = (id: string) => {
    const client = useGraphQLClient();

    return useMutation(async (image: number): Promise<string> => {
        const {
            removeImage: { id: updatedId },
        } = await client.request(
            gql`
                mutation ($data: RemoveImageInput!) {
                    removeImage(data: $data) {
                        id
                    }
                }
            `,
            { data: { id, image } }
        );

        return updatedId;
    });
};

const StyledView = styled(View);
const StyledSurface = styled(Surface);
const StyledTextInput = styled(TextInput, 'bg-white');
const StyledListItem = styled(List.Item);
const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView);
const StyledScrollView = styled(ScrollView);
const StyledIconButton = styled(IconButton);

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    secondary_name: Yup.string().required(),
    country: Yup.string().required(),
    region: Yup.string().required(),
    province: Yup.string().required(),
    city: Yup.string().required(),
    phone_number: Yup.string(),
    email: Yup.string().email(),
});

const Edit: FC<Props & NativeStackScreenProps<any>> = ({
    navigation,
    route,
}) => {
    const { t, i18n } = useTranslation('Retailers/Create');

    const { colors } = useTheme();
    const queryClient = useQueryClient();

    const { data: retailer, isLoading } = useRetailerQuery(route.params!.id);

    const updateRetailer = useUpdateRetailerMutation(route.params!.id);
    const removeImage = useRemoveImageMutation(route.params!.id);

    const { data: countries, isLoading: isLoadingCountries } =
        useCountriesQuery();

    const { data: categories, isLoading: isLoadingCategories } =
        useCategoriesQuery();

    const countriesModalRef = useRef<Modalize>(null);
    const regionsModalRef = useRef<Modalize>(null);
    const provincesModalRef = useRef<Modalize>(null);
    const citiesModalRef = useRef<Modalize>(null);
    const suburbsModalRef = useRef<Modalize>(null);

    const [locationModalVisible, setLocationModalVisible] = useState(false);

    const onOpen = (ref: RefObject<Modalize>) => {
        ref.current?.open();
    };

    const onClose = (ref: RefObject<Modalize>) => {
        ref.current?.close();
    };

    const initialValues: Values<string> = {
        name: retailer?.name || '',
        secondary_name: retailer?.secondary_name || '',
        phone_number:
            retailer?.phone_number?.replace(
                `+${retailer.country.tags.phone_code}`,
                ''
            ) || '',
        country: retailer?.country.tags.ISO3166_1 || '',
        region: retailer?.region?.tags.ISO3166_2 || '',
        province: retailer?.province?.tags.ISO3166_2 || '',
        city: retailer?.city?.id || '',
        suburb: retailer?.suburb?.id || '',
        email: retailer?.email || '',
        whatsapp:
            retailer?.whatsapp?.replace(
                `+${retailer.country.tags.phone_code}`,
                ''
            ) || '',
        categories: (retailer?.categories || []).map(
            (category) => +category.id
        ),
        address1: retailer?.address1 || '',
        address2: retailer?.address2 || '',
        address3: retailer?.address3 || '',
        notes: retailer?.notes || '',
        zipcode: retailer?.zipcode || '',
        location: retailer?.location || null,
        owns_smartphone: retailer?.owns_smartphone || false,
        images: retailer?.images || [],
        working_hours: mapValues(
            pickBy(retailer?.working_hours || {}, Boolean),
            (ranges) => {
                const transformed = (ranges || []).map((range) => {
                    const parts = range.split('-');

                    return {
                        from: parts[0],
                        to: parts.length > 1 ? parts[1] : '',
                    };
                });

                return [
                    ...transformed,
                    ...(transformed.length < 2 ? [{ from: '', to: '' }] : []),
                ];
            }
        ),
    };

    const {
        values,
        handleBlur,
        isSubmitting,
        handleChange,
        handleSubmit,
        touched,
        errors,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,
        validateOnChange: false,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (
            values: Values<string>,
            { setSubmitting }: FormikHelpers<Values<string>>
        ) => {
            try {
                const images: File[] = [];

                for (let [key, image] of values.images.entries()) {
                    if (!image.startsWith('http')) {
                        const res = await fetch(image);
                        const blob = await res.blob();
                        const buffer = await blob.arrayBuffer();
                        const file = new File(
                            [buffer],
                            `image_${key}.${blob.type.replace('image/', '')}`,
                            { type: blob.type }
                        );

                        images.push(file);
                    }
                }

                updateRetailer.mutate(
                    {
                        ...values,
                        images,
                        working_hours: Object.entries(
                            values.working_hours
                        ).reduce((acc: any, [day, ranges]: any) => {
                            const hours = ranges
                                .map((range: any) =>
                                    Object.values(range)
                                        .filter(Boolean)
                                        .join('-')
                                )
                                .filter(Boolean);

                            if (Boolean(hours.length)) {
                                acc[day] = hours;
                            }

                            return acc;
                        }, {}),
                    },
                    {
                        onSuccess: async () => {
                            await queryClient.invalidateQueries(['retailers']);
                            setSubmitting(false);
                            navigation.navigate('Retailers/All');
                        },
                        onError: (err: any) => {
                            err.response.errors.forEach((error: any) => {
                                if (
                                    error.extensions.category === 'validation'
                                ) {
                                    setErrors(
                                        transform(
                                            error.extensions.validation,
                                            (res: any, val: any, key: any) => {
                                                res[key.replace('data.', '')] =
                                                    val[0];
                                            }
                                        )
                                    );
                                }
                            });
                            setSubmitting(false);
                        },
                    }
                );
            } catch (err) {
                setSubmitting(false);
            }
        },
    });

    const pickImage = async () => {
        try {
            let result = await launchImageLibraryAsync({
                allowsMultipleSelection: true,
                mediaTypes: MediaTypeOptions.Images,
                quality: 1,
            });

            if (!result.cancelled) {
                const newValue = [...values.images];

                for (let s of result.selected) {
                    newValue.push(s.uri);
                }

                setFieldValue('images', newValue);
            }
        } catch (err) {}
    };

    useEffect(() => {
        (async () => {
            try {
                let { status } = await requestForegroundPermissionsAsync();

                if (status !== 'granted') {
                    throw new Error('Permission to access location was denied');
                }
            } catch (err) {}
        })();
    }, []);

    const country = find(countries || [], ['tags.ISO3166_1', values.country]);

    const { data: regions, isLoading: isLoadingRegions } = useRegionsQuery(
        values.country
    );

    const region = find(regions || [], ['tags.ISO3166_2', values.region]);

    const { data: provinces, isLoading: isLoadingProvinces } =
        useProvincesQuery(values.region);

    const province = find(provinces || [], ['tags.ISO3166_2', values.province]);

    const { data: cities, isLoading: isLoadingCities } = useCitiesQuery(
        values.province
    );

    const city = find(cities || [], ['id', values.city]);

    const { data: suburbs, isLoading: isLoadingSuburbs } = useSuburbsQuery(
        values.province,
        values.city
    );

    const suburb = find(suburbs || [], ['id', values.suburb]);

    return (
        <StyledKeyboardAvoidingView
            className="bg-white flex-1 justify-center items-center"
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
            <StyledScrollView
                className="container p-4 space-y-4 flex-1"
                showsVerticalScrollIndicator={false}>
                <View>
                    <StyledTextInput
                        label={t('name')}
                        mode="outlined"
                        error={Boolean(touched.name) && Boolean(errors.name)}
                        onChangeText={handleChange('name')}
                        onBlur={handleBlur('name')}
                        value={values.name}
                    />
                    {Boolean(touched.name) && Boolean(errors.name) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.name) && Boolean(errors.name)
                            }>
                            {t('validation.name')}
                        </HelperText>
                    )}
                </View>
                <View>
                    <StyledTextInput
                        label={t('secondary_name')}
                        mode="outlined"
                        error={
                            Boolean(touched.secondary_name) &&
                            Boolean(errors.secondary_name)
                        }
                        onChangeText={handleChange('secondary_name')}
                        onBlur={handleBlur('secondary_name')}
                        value={values.secondary_name}
                    />
                    {Boolean(touched.secondary_name) &&
                        Boolean(errors.secondary_name) && (
                            <HelperText
                                type="error"
                                visible={
                                    Boolean(touched.secondary_name) &&
                                    Boolean(errors.secondary_name)
                                }>
                                {t('validation.secondary_name')}
                            </HelperText>
                        )}
                </View>
                <View>
                    <Button
                        mode="outlined"
                        disabled={true}
                        loading={isLoadingCountries}
                        onPress={() => {
                            onOpen(countriesModalRef);
                        }}
                        icon="chevron-down"
                        labelStyle={{ display: 'flex' }}
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            flexDirection:
                                i18n.dir() === 'rtl' ? 'row' : 'row-reverse',
                            height: 46,
                        }}>
                        <StyledView
                            className={`${
                                i18n.dir() === 'rtl'
                                    ? 'flex-row-reverse'
                                    : 'flex-row'
                            } items-center justify-center space-x-2`}>
                            {Boolean(country) && (
                                <Image
                                    source={{
                                        uri: country!.tags.flag,
                                        width: 30,
                                        height: 20,
                                    }}
                                />
                            )}
                            <Text style={{ color: colors.primary }}>
                                {get(
                                    country?.tags,
                                    `name_${i18n.language}`,
                                    country?.tags.name
                                ) || t('country')}
                            </Text>
                        </StyledView>
                    </Button>

                    {Boolean(touched.country) && Boolean(errors.country) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.country) &&
                                Boolean(errors.country)
                            }>
                            {t('validation.country')}
                        </HelperText>
                    )}

                    <Portal>
                        <Modalize
                            ref={countriesModalRef}
                            modalTopOffset={30}
                            handleStyle={{
                                height: 10,
                                borderRadius: 5,
                                top: -10,
                            }}
                            flatListProps={{
                                data: countries,
                                renderItem: ({
                                    item,
                                }: ListRenderItemInfo<Country>) => (
                                    <List.Item
                                        title={() => (
                                            <StyledView className="flex-row items-center justify-center space-x-2">
                                                <Image
                                                    source={{
                                                        uri: item.tags.flag,
                                                        width: 30,
                                                        height: 20,
                                                    }}
                                                />
                                                <Text>
                                                    {get(
                                                        item.tags,
                                                        `name_${i18n.language}`,
                                                        item.tags.name
                                                    )}
                                                </Text>
                                            </StyledView>
                                        )}
                                        onPress={() => {
                                            setFieldValue(
                                                'country',
                                                item.tags.ISO3166_1
                                            );
                                            setFieldValue('region', '');
                                            setFieldValue('province', '');
                                            setFieldValue('city', '');
                                            setFieldValue('suburb', '');
                                            onClose(countriesModalRef);
                                        }}
                                    />
                                ),
                                keyExtractor: (item: any) => item.id,
                                showsVerticalScrollIndicator: false,
                            }}
                        />
                    </Portal>
                </View>
                <View>
                    <StyledTextInput
                        disabled={!Boolean(country)}
                        label={t('phone_number')}
                        keyboardType="phone-pad"
                        mode="outlined"
                        error={
                            Boolean(touched.phone_number) &&
                            Boolean(errors.phone_number)
                        }
                        onChangeText={handleChange('phone_number')}
                        onBlur={handleBlur('phone_number')}
                        value={values.phone_number}
                        left={
                            Boolean(country) && (
                                <TextInput.Affix
                                    text={`+${country?.tags.phone_code}`}
                                />
                            )
                        }
                        right={<TextInput.Icon name="phone" />}
                    />
                    {Boolean(touched.phone_number) &&
                        Boolean(errors.phone_number) && (
                            <HelperText
                                type="error"
                                visible={
                                    Boolean(touched.phone_number) &&
                                    Boolean(errors.phone_number)
                                }>
                                {t('validation.phone_number')}
                            </HelperText>
                        )}
                </View>
                <View>
                    <Button
                        mode="outlined"
                        disabled={!Boolean(country) || isLoadingRegions}
                        loading={Boolean(country) && isLoadingRegions}
                        onPress={() => {
                            onOpen(regionsModalRef);
                        }}
                        icon="chevron-down"
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            flexDirection:
                                i18n.dir() === 'rtl' ? 'row' : 'row-reverse',
                            height: 46,
                        }}>
                        {get(
                            region?.tags,
                            `name_${i18n.language}`,
                            region?.tags.name
                        ) || t('region')}
                    </Button>

                    {Boolean(touched.region) && Boolean(errors.region) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.region) &&
                                Boolean(errors.region)
                            }>
                            {t('validation.region')}
                        </HelperText>
                    )}

                    <Portal>
                        <Modalize
                            ref={regionsModalRef}
                            modalTopOffset={30}
                            handleStyle={{
                                height: 10,
                                borderRadius: 5,
                                top: -10,
                            }}
                            flatListProps={{
                                data: regions,
                                renderItem: ({
                                    item,
                                }: ListRenderItemInfo<Region>) => (
                                    <StyledListItem
                                        className="items-center"
                                        title={get(
                                            item.tags,
                                            `name_${i18n.language}`,
                                            item.tags.name
                                        )}
                                        onPress={() => {
                                            setFieldValue(
                                                'region',
                                                item.tags.ISO3166_2
                                            );
                                            setFieldValue('province', '');
                                            setFieldValue('city', '');
                                            setFieldValue('suburb', '');
                                            onClose(regionsModalRef);
                                        }}
                                    />
                                ),
                                keyExtractor: (item: any) => item.id,
                                showsVerticalScrollIndicator: false,
                            }}
                        />
                    </Portal>
                </View>
                <View>
                    <Button
                        mode="outlined"
                        disabled={!Boolean(region) || isLoadingProvinces}
                        loading={Boolean(region) && isLoadingProvinces}
                        onPress={() => {
                            onOpen(provincesModalRef);
                        }}
                        icon="chevron-down"
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            flexDirection:
                                i18n.dir() === 'rtl' ? 'row' : 'row-reverse',
                            height: 46,
                        }}>
                        {get(
                            province?.tags,
                            `name_${i18n.language}`,
                            province?.tags.name
                        ) || t('province')}
                    </Button>

                    {Boolean(touched.province) && Boolean(errors.province) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.province) &&
                                Boolean(errors.province)
                            }>
                            {t('validation.province')}
                        </HelperText>
                    )}

                    <Portal>
                        <Modalize
                            ref={provincesModalRef}
                            modalTopOffset={30}
                            handleStyle={{
                                height: 10,
                                borderRadius: 5,
                                top: -10,
                            }}
                            flatListProps={{
                                data: provinces,
                                renderItem: ({
                                    item,
                                }: ListRenderItemInfo<Province>) => (
                                    <StyledListItem
                                        className="items-center"
                                        title={get(
                                            item.tags,
                                            `name_${i18n.language}`,
                                            item.tags.name
                                        )}
                                        onPress={() => {
                                            setFieldValue(
                                                'province',
                                                item.tags.ISO3166_2
                                            );
                                            setFieldValue('city', '');
                                            setFieldValue('suburb', '');
                                            onClose(provincesModalRef);
                                        }}
                                    />
                                ),
                                keyExtractor: (item: any) => item.id,
                                showsVerticalScrollIndicator: false,
                            }}
                        />
                    </Portal>
                </View>
                <View>
                    <Button
                        mode="outlined"
                        disabled={!Boolean(province) || isLoadingCities}
                        loading={Boolean(province) && isLoadingCities}
                        onPress={() => {
                            onOpen(citiesModalRef);
                        }}
                        icon="chevron-down"
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            flexDirection:
                                i18n.dir() === 'rtl' ? 'row' : 'row-reverse',
                            height: 46,
                        }}>
                        {get(
                            city?.tags,
                            `name_${i18n.language}`,
                            city?.tags.name
                        ) || t('city')}
                    </Button>

                    {Boolean(touched.city) && Boolean(errors.city) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.city) && Boolean(errors.city)
                            }>
                            {t('validation.city')}
                        </HelperText>
                    )}

                    <Portal>
                        <Modalize
                            ref={citiesModalRef}
                            modalTopOffset={30}
                            handleStyle={{
                                height: 10,
                                borderRadius: 5,
                                top: -10,
                            }}
                            flatListProps={{
                                data: cities,
                                renderItem: ({
                                    item,
                                }: ListRenderItemInfo<City>) => (
                                    <StyledListItem
                                        className="items-center"
                                        title={
                                            <Text
                                                style={[
                                                    item.tags.place ===
                                                    'village'
                                                        ? {
                                                              fontStyle:
                                                                  'italic',
                                                          }
                                                        : {},
                                                    ,
                                                    item.tags.place === 'city'
                                                        ? {
                                                              fontWeight:
                                                                  'bold',
                                                          }
                                                        : {},
                                                ]}>
                                                {get(
                                                    item.tags,
                                                    `name_${i18n.language}`,
                                                    item.tags.name
                                                )}
                                            </Text>
                                        }
                                        onPress={() => {
                                            setFieldValue('city', item.id);
                                            setFieldValue('suburb', '');
                                            onClose(citiesModalRef);
                                        }}
                                    />
                                ),
                                keyExtractor: (item: any) => item.id,
                                showsVerticalScrollIndicator: false,
                            }}
                        />
                    </Portal>
                </View>
                {Boolean(city) && Boolean(suburbs?.length || 0) && (
                    <View>
                        <Button
                            mode="outlined"
                            disabled={isLoadingSuburbs}
                            loading={isLoadingSuburbs}
                            onPress={() => {
                                onOpen(suburbsModalRef);
                            }}
                            icon="chevron-down"
                            style={{ borderRadius: 4, marginTop: 6 }}
                            contentStyle={{
                                flexDirection:
                                    i18n.dir() === 'rtl'
                                        ? 'row'
                                        : 'row-reverse',
                                height: 46,
                            }}>
                            {get(
                                suburb?.tags,
                                `name_${i18n.language}`,
                                suburb?.tags.name
                            ) || t('suburb')}
                        </Button>

                        <Portal>
                            <Modalize
                                ref={suburbsModalRef}
                                modalTopOffset={30}
                                handleStyle={{
                                    height: 10,
                                    borderRadius: 5,
                                    top: -10,
                                }}
                                flatListProps={{
                                    data: suburbs,
                                    renderItem: ({
                                        item,
                                    }: ListRenderItemInfo<Suburb>) => (
                                        <StyledListItem
                                            className="items-center"
                                            title={get(
                                                item.tags,
                                                `name_${i18n.language}`,
                                                item.tags.name
                                            )}
                                            onPress={() => {
                                                setFieldValue(
                                                    'suburb',
                                                    item.id
                                                );
                                                onClose(suburbsModalRef);
                                            }}
                                        />
                                    ),
                                    keyExtractor: (item: any) => item.id,
                                    showsVerticalScrollIndicator: false,
                                }}
                            />
                        </Portal>
                    </View>
                )}
                <View>
                    <StyledTextInput
                        label={t('address', { line: 1 })}
                        mode="outlined"
                        error={
                            Boolean(touched.address1) &&
                            Boolean(errors.address1)
                        }
                        onChangeText={handleChange('address1')}
                        onBlur={handleBlur('address1')}
                        value={values.address1}
                        right={<TextInput.Icon name="store" />}
                    />
                    {Boolean(touched.address1) && Boolean(errors.address1) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.address1) &&
                                Boolean(errors.address1)
                            }>
                            {t('validation.address')}
                        </HelperText>
                    )}
                </View>
                <View>
                    <StyledTextInput
                        label={t('address', { line: 2 })}
                        mode="outlined"
                        error={
                            Boolean(touched.address2) &&
                            Boolean(errors.address2)
                        }
                        onChangeText={handleChange('address2')}
                        onBlur={handleBlur('address2')}
                        value={values.address2}
                        right={<TextInput.Icon name="store" />}
                    />
                    {Boolean(touched.address2) && Boolean(errors.address2) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.address2) &&
                                Boolean(errors.address2)
                            }>
                            {t('validation.address')}
                        </HelperText>
                    )}
                </View>
                <View>
                    <StyledTextInput
                        label={t('address', { line: 3 })}
                        mode="outlined"
                        error={
                            Boolean(touched.address3) &&
                            Boolean(errors.address3)
                        }
                        onChangeText={handleChange('address3')}
                        onBlur={handleBlur('address3')}
                        value={values.address3}
                        right={<TextInput.Icon name="store" />}
                    />
                    {Boolean(touched.address3) && Boolean(errors.address3) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.address3) &&
                                Boolean(errors.address3)
                            }>
                            {t('validation.address')}
                        </HelperText>
                    )}
                </View>
                <View>
                    <StyledTextInput
                        label={t('zipcode')}
                        mode="outlined"
                        error={
                            Boolean(touched.zipcode) && Boolean(errors.zipcode)
                        }
                        onChangeText={handleChange('zipcode')}
                        onBlur={handleBlur('zipcode')}
                        value={values.zipcode}
                    />
                    {Boolean(touched.zipcode) && Boolean(errors.zipcode) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.zipcode) &&
                                Boolean(errors.zipcode)
                            }>
                            label={t('validation.zipcode')}
                        </HelperText>
                    )}
                </View>
                <View>
                    <Button
                        icon={Boolean(values.location) ? 'check' : 'map-marker'}
                        mode="outlined"
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            height: 46,
                        }}
                        onPress={() => {
                            setLocationModalVisible(true);
                        }}>
                        {t('location')}
                    </Button>

                    <Portal>
                        <Modal
                            visible={locationModalVisible}
                            contentContainerStyle={{
                                backgroundColor: 'white',
                                flex: 1,
                            }}>
                            <MapComponent
                                flyTo={values.location}
                                onCancel={() => {
                                    setLocationModalVisible(false);
                                }}
                                onSelectPosition={(position: Point) => {
                                    setFieldValue('location', position);
                                    setLocationModalVisible(false);
                                }}
                            />
                        </Modal>
                    </Portal>
                </View>
                <View>
                    <Button
                        icon="camera"
                        mode="outlined"
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            height: 46,
                        }}
                        onPress={pickImage}>
                        {t('photos')}
                    </Button>
                    {(Boolean(values.images.length) || true) && (
                        <FlatList
                            style={{ backgroundColor: 'white' }}
                            horizontal
                            data={values.images}
                            keyExtractor={(item, index) => `${index}`}
                            renderItem={({
                                item,
                                index,
                            }: ListRenderItemInfo<string>) => (
                                <StyledSurface className="bg-white p-1 rounded relative mx-2 my-4">
                                    <StyledIconButton
                                        icon="close"
                                        disabled={removeImage.isLoading}
                                        mode="contained"
                                        iconColor="red"
                                        size={16}
                                        className="absolute  top-[-18] right-[-18] z-10 border-red-400 border bg-white"
                                        onPress={() => {
                                            if (
                                                values.images[index].startsWith(
                                                    'http'
                                                )
                                            ) {
                                                removeImage.mutate(index, {
                                                    onSuccess: () => {
                                                        queryClient.invalidateQueries(
                                                            [
                                                                'retailer',
                                                                route.params!
                                                                    .id,
                                                            ]
                                                        );
                                                    },
                                                });
                                            } else {
                                                const newValue = [
                                                    ...values.images,
                                                ];
                                                newValue.splice(index, 1);
                                                setFieldValue(
                                                    'images',
                                                    newValue
                                                );
                                            }
                                        }}
                                    />
                                    <Image
                                        key={index}
                                        source={{
                                            uri: item,
                                            width: 100,
                                            height: 100,
                                        }}
                                    />
                                </StyledSurface>
                            )}
                        />
                    )}
                </View>
                <View>
                    <StyledTextInput
                        label={t('email')}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        mode="outlined"
                        error={Boolean(touched.email) && Boolean(errors.email)}
                        onChangeText={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        right={<TextInput.Icon name="at" />}
                    />
                    {Boolean(touched.email) && Boolean(errors.email) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.email) && Boolean(errors.email)
                            }>
                            {t('validation.email')}
                        </HelperText>
                    )}
                </View>
                <View>
                    <StyledTextInput
                        keyboardType="phone-pad"
                        disabled={!Boolean(values.country)}
                        label={t('whatsapp')}
                        mode="outlined"
                        left={
                            Boolean(country) && (
                                <TextInput.Affix
                                    text={`+${country?.tags.phone_code}`}
                                />
                            )
                        }
                        right={<TextInput.Icon name="whatsapp" />}
                        error={
                            Boolean(touched.whatsapp) &&
                            Boolean(errors.whatsapp)
                        }
                        onChangeText={handleChange('whatsapp')}
                        onBlur={handleBlur('whatsapp')}
                        value={values.whatsapp}
                    />
                    {Boolean(touched.whatsapp) && Boolean(errors.whatsapp) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.whatsapp) &&
                                Boolean(errors.whatsapp)
                            }>
                            {t('validation.whatsapp')}
                        </HelperText>
                    )}
                </View>
                <StyledView className="space-y-4">
                    <Text variant="labelLarge">{t('categories')}</Text>
                    <StyledView>
                        <FlatList
                            data={categories || []}
                            renderItem={({ item, index: itemIndex }) => {
                                const index = values.categories.indexOf(
                                    +item.id
                                );

                                const selected = index !== -1;

                                return (
                                    <Chip
                                        style={{
                                            padding: 4,
                                            marginTop: 4,
                                            marginBottom:
                                                itemIndex === 2 ? 12 : 4,
                                            backgroundColor: 'white',
                                        }}
                                        mode="outlined"
                                        selected={selected}
                                        selectedColor={
                                            selected
                                                ? tailwindColors.green[500]
                                                : undefined
                                        }
                                        showSelectedOverlay={false}
                                        onPress={() => {
                                            const newValue = [
                                                ...values.categories,
                                            ];

                                            if (selected) {
                                                newValue.splice(index, 1);
                                            } else {
                                                newValue.push(+item.id);
                                            }

                                            setFieldValue(
                                                'categories',
                                                newValue
                                            );
                                        }}>
                                        {get(item.name, i18n.language)}
                                    </Chip>
                                );
                            }}
                            keyExtractor={(item) => item.id}
                        />
                    </StyledView>
                </StyledView>
                <View>
                    <StyledTextInput
                        label={t('notes')}
                        mode="outlined"
                        error={Boolean(touched.notes) && Boolean(errors.notes)}
                        onChangeText={handleChange('notes')}
                        onBlur={handleBlur('notes')}
                        value={values.notes}
                        right={<TextInput.Icon name="notebook" />}
                    />
                    {Boolean(touched.notes) && Boolean(errors.notes) && (
                        <HelperText
                            type="error"
                            visible={
                                Boolean(touched.notes) && Boolean(errors.notes)
                            }>
                            {t('validation.notes')}
                        </HelperText>
                    )}
                </View>
                <StyledView className="hidden">
                    <Text variant="labelLarge">Horaires d'ouverture</Text>
                    <WorkingHours
                        current={values.working_hours}
                        onChange={(key: string, value: string) => {
                            setFieldValue(`working_hours.${key}`, value);
                        }}
                        onPress={(item: string) => {
                            setFieldValue(
                                'working_hours',
                                has(values.working_hours, item)
                                    ? omit(values.working_hours, item)
                                    : {
                                          ...values.working_hours,
                                          [`${item}`]: [
                                              { from: '', to: '' },
                                              { from: '', to: '' },
                                          ],
                                      }
                            );
                        }}
                    />
                </StyledView>
                <StyledView className="flex-row space-x-4 items-center">
                    <Text variant="labelLarge">{t('owns_smartphone')}</Text>
                    <StyledView>
                        <RadioButton.Group
                            onValueChange={(value) => {
                                setFieldValue(
                                    'owns_smartphone',
                                    value === 'yes'
                                );
                            }}
                            value={values.owns_smartphone ? 'yes' : 'no'}>
                            <StyledView className="flex-row space-x-2 items-center">
                                <StyledView
                                    className={`${
                                        i18n.dir() === 'rtl'
                                            ? 'flex-row-reverse'
                                            : 'flex-row'
                                    } items-center justify-center space-x-2`}>
                                    <Text>{t('yes')}</Text>
                                    <RadioButton value="yes" />
                                </StyledView>
                                <StyledView
                                    className={`${
                                        i18n.dir() === 'rtl'
                                            ? 'flex-row-reverse'
                                            : 'flex-row'
                                    } items-center justify-center space-x-2`}>
                                    <Text>{t('no')}</Text>
                                    <RadioButton value="no" />
                                </StyledView>
                            </StyledView>
                        </RadioButton.Group>
                    </StyledView>
                </StyledView>
                <View>
                    <Button
                        onPress={handleSubmit}
                        mode="contained"
                        disabled={isSubmitting || updateRetailer.isLoading}
                        loading={isSubmitting || updateRetailer.isLoading}
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            height: 46,
                        }}>
                        {t('buttons.confirm')}
                    </Button>
                </View>
            </StyledScrollView>
        </StyledKeyboardAvoidingView>
    );
};

export default Edit;
