import "setimmediate";
import {
    NavigationContainer,
    DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import {
    createNativeStackNavigator,
    NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import React, { FC, Suspense, useEffect, useState } from 'react';
import Constants from 'expo-constants';
import { View, Appearance, Text, I18nManager } from 'react-native';
import {
    Appbar,
    MD3DarkTheme as PaperDarkTheme,
    MD3LightTheme as PaperDefaultTheme,
    Provider as PaperProvider,
    Snackbar,
} from 'react-native-paper';
import merge from 'deepmerge';
import { useMMKVString } from 'react-native-mmkv';
import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
    useMutation,
} from '@tanstack/react-query';
import Login from './src/components/pages/Login';
import CreateRetailer from './src/components/pages/Retailers/Create';
import EditRetailer from './src/components/pages/Retailers/Edit';
import AllRetailers from './src/components/pages/Retailers/All';
import 'react-native-gesture-handler';
import { ToastProvider } from 'react-native-toast-notifications';
import colors from 'tailwindcss/colors';
import { useTranslation } from 'react-i18next';
import './i18n';
import useGraphQLClient from './src/graphql';
import { gql } from 'graphql-request';
import { useFonts } from 'expo-font';

const CombinedDefaultTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
const CombinedDarkTheme = merge(PaperDarkTheme, NavigationDarkTheme);
const colorScheme = Appearance.getColorScheme();

const Theme =
    colorScheme === 'dark' ? CombinedDefaultTheme : CombinedDefaultTheme;

interface NavBarProps {
    isRTL: boolean;
}

const NavBar: FC<NavBarProps & NativeStackHeaderProps> = ({
    navigation,
    back,
    isRTL,
    route,
    options,
}) => {
    const [, setToken] = useMMKVString('token');
    const client = useGraphQLClient();

    return (
        <Appbar.Header>
            {back && (
                <Appbar.BackAction
                    style={isRTL ? { transform: [{ scaleX: -1 }] } : {}}
                    onPress={navigation.goBack}
                />
            )}
            <Appbar.Content title={options.title || route.name} />
            <Appbar.Action
                icon="logout"
                onPress={async () => {
                    try {
                        await client.request(
                            gql`
                                mutation {
                                    logout
                                }
                            `
                        );
                    } catch (e) { }
                    setToken(undefined);
                }}
            />
        </Appbar.Header>
    );
};

const Stack = createNativeStackNavigator();

const linking = {
    prefixes: [Constants.manifest?.extra?.appUrl],
    config: {
        initialRouteName: 'Retailers/All',
        screens: {
            Login: { path: 'login' },
            'Retailers/All': {
                path: 'stores',
                screens: { ListView: 'list', MapView: 'map' },
            },
            'Retailers/Create': { path: 'stores/create' },
            'Retailers/Edit': { path: 'stores/edit/:id' },
        },
    },
};

const Main: FC<any> = () => {
    const { t, i18n } = useTranslation('headers');
    const [token, setToken] = useMMKVString('token');
    useFonts({
        IcoMoon: require('./assets/icomoon/xarage.ttf'),
    });

    useEffect(() => {
        I18nManager.forceRTL(i18n.dir() === 'rtl');
    }, [i18n.language]);

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error: any) => {
                if (Boolean(error?.response?.errors?.length || 0)) {
                    if (
                        error.response.errors[0].message === 'Unauthenticated.'
                    ) {
                        setToken(undefined);
                    }
                }
            },
        }),
    });

    return (
        <QueryClientProvider client={queryClient}>
            <PaperProvider theme={Theme}>
                <NavigationContainer theme={Theme} linking={linking}>
                    <ToastProvider
                        successColor={colors.green[600]}
                        dangerColor={colors.red[600]}
                        warningColor={colors.yellow[500]}
                        renderType={{
                            custom_type: (toast) => (
                                <Snackbar
                                    visible={toast.open}
                                    duration={toast.duration}
                                    onDismiss={toast.onHide}>
                                    {toast.message}
                                </Snackbar>
                            ),
                        }}>
                        <Stack.Navigator>
                            {Boolean(token) ? (
                                <Stack.Group
                                    screenOptions={{
                                        header: (props) => (
                                            <NavBar
                                                {...props}
                                                isRTL={i18n.dir() === 'rtl'}
                                            />
                                        ),
                                    }}>
                                    <Stack.Screen
                                        name="Retailers/All"
                                        component={AllRetailers}
                                        options={{ title: t('retailers') }}
                                    />
                                    <Stack.Screen
                                        name="Retailers/Create"
                                        component={CreateRetailer}
                                        options={{
                                            title: t('create_retailer'),
                                        }}
                                    />
                                    <Stack.Screen
                                        name="Retailers/Edit"
                                        component={EditRetailer}
                                        options={{
                                            title: t('edit_retailer'),
                                        }}
                                    />
                                </Stack.Group>
                            ) : (
                                <Stack.Screen
                                    name="Login"
                                    component={Login}
                                    options={{
                                        headerShown: false,
                                    }}
                                />
                            )}
                        </Stack.Navigator>
                    </ToastProvider>
                </NavigationContainer>
            </PaperProvider>
        </QueryClientProvider>
    );
};

const App: FC<any> = () => {
    return (
        <Suspense fallback="loading">
            <Main />
        </Suspense>
    );
};

export default App;
