import { FC, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { Text, Button } from 'react-native-paper';
import maplibregl from 'maplibre-gl';
import mapStyle from '../../../../utils/bright.json';
import { styled } from 'nativewind';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useTranslation } from 'react-i18next';
import { Point } from '../../../../models/Retailer';

const StyledView = styled(View);
const StyledButton = styled(Button, 'rounded-[4px]');

interface Props {
    onSelectPosition: any;
    onCancel: any;
    flyTo: Point | null;
}

const Map: FC<Props> = ({ onSelectPosition, onCancel, flyTo }) => {
    const { t } = useTranslation('Map');
    const mapContainer = useRef<any>(null);
    const map = useRef<any>(null);

    const geolocate = new maplibregl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true,
        },
        trackUserLocation: true,
    });

    const marker = new maplibregl.Marker();

    useEffect(() => {
        if (map.current) return;

        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: mapStyle as any,
            center: [-7.65103, 33.51102],
            zoom: 9,
        });

        map.current.addControl(geolocate);

        marker.setLngLat(map.current.getCenter()).addTo(map.current);

        map.current.on('move', (e: any) => {
            marker.setLngLat(e.target.getCenter());
        });

        map.current.on('load', function () {
            if (!Boolean(flyTo)) {
                geolocate.trigger();
            } else {
                map.current.addSource('retailer', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'Point',
                                    coordinates: [flyTo!.lng, flyTo!.lat],
                                },
                            },
                        ],
                    },
                });

                map.current.addLayer({
                    id: 'retailer-point',
                    type: 'circle',
                    source: 'retailer',
                    paint: {
                        'circle-color': '#11b4da',
                        'circle-radius': 8,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff',
                    },
                });

                map.current.flyTo({ center: flyTo, zoom: 16, essential: true });
            }
        });
    }, []);

    return (
        <StyledView className="flex-1">
            <StyledView ref={mapContainer} className="absolute w-full h-full" />
            <StyledView className="absolute w-full p-4 bottom-8 space-y-4">
                <StyledButton
                    onPress={() => {
                        onSelectPosition(marker.getLngLat());
                    }}
                    mode="contained"
                    contentStyle={{
                        height: 46,
                    }}>
                    {t('use_location')}
                </StyledButton>
                <StyledButton
                    onPress={onCancel}
                    mode="contained"
                    className="bg-red-600"
                    contentStyle={{
                        height: 46,
                    }}>
                    {t('cancel')}
                </StyledButton>
            </StyledView>
        </StyledView>
    );
};

export default Map;
