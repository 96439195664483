import React, { FC } from 'react';
import { ListRenderItemInfo, View } from 'react-native';
import { Chip, Text } from 'react-native-paper';
import { FlatList } from 'react-native-gesture-handler';
import { styled } from 'nativewind';
import TimePicker from '../../atoms/TimePicker';

const StyledView = styled(View);

const DAYS = [
    {
        label: 'Lundi',
        key: 'monday',
    },
    {
        label: 'Mardi',
        key: 'tuesday',
    },
    {
        label: 'Mercredi',
        key: 'wednesday',
    },
    {
        label: 'Jeudi',
        key: 'thursday',
    },
    {
        label: 'Vendredi',
        key: 'friday',
    },
    {
        label: 'Samedi',
        key: 'saturday',
    },
    {
        label: 'Dimanche',
        key: 'sunday',
    },
];

const WorkingHours: FC<any> = ({ onPress, onChange, current = {} }) => {
    return (
        <FlatList
            data={DAYS}
            keyExtractor={(item, index) => `${index}`}
            renderItem={({
                item,
            }: ListRenderItemInfo<{ key: string; label: string }>) => {
                const day = current[item.key];

                return (
                    <StyledView className="m-2 space-x-4 items-center">
                        <Chip
                            mode="outlined"
                            selected={Boolean(day)}
                            onPress={() => {
                                onPress(item.key);
                            }}>
                            {item.label}
                        </Chip>
                        {Boolean(day) && (
                            <StyledView className="space-y-4 mt-4">
                                {day.map((range: any, index: any) => (
                                    <StyledView
                                        key={index}
                                        className="flex-row space-x-2 items-center">
                                        <Text>de</Text>
                                        <StyledView className="flex-1">
                                            <TimePicker
                                                value={range.from}
                                                onChange={(event: any) => {
                                                    onChange(
                                                        `${item.key}.${index}.from`,
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </StyledView>
                                        <Text>à</Text>
                                        <StyledView className="flex-1">
                                            <TimePicker
                                                value={range.to}
                                                onChange={(event: any) => {
                                                    onChange(
                                                        `${item.key}.${index}.to`,
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </StyledView>
                                    </StyledView>
                                ))}
                            </StyledView>
                        )}
                    </StyledView>
                );
            }}
        />
    );
};

export default WorkingHours;
