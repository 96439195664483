import { styled } from 'nativewind';
import React, { FC, useEffect, useState } from 'react';
import { ListRenderItemInfo, View } from 'react-native';
import {
    FAB,
    List as RNPList,
    Divider,
    Searchbar,
    Text,
    Badge,
} from 'react-native-paper';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Retailer } from '../../../../../models/Retailer';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native-gesture-handler';
import { Edge } from '../../../../../models/common';
import { flatMap, get } from 'lodash';
import tailwindColors from 'tailwindcss/colors';
import { useRetailersQuery } from '../../../../../graphql/hooks';

interface Props {}

const StyledView = styled(View);
const StyledFAB = styled(FAB);
const StyledSearchbar = styled(Searchbar);

function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}

const ListView: FC<Props & NativeStackScreenProps<any>> = ({ navigation }) => {
    const { t, i18n } = useTranslation('Retailers/All', {
        keyPrefix: 'ListView',
    });

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 300);
    const { data, fetchNextPage, hasNextPage } = useRetailersQuery(
        20,
        debouncedSearch
    );

    return (
        <StyledView className="relative flex-1 bg-white">
            <StyledView className="p-4">
                <StyledSearchbar
                    className="bg-white"
                    value={search}
                    placeholder={t('search')}
                    onChangeText={setSearch}
                />
            </StyledView>

            <FlatList
                contentContainerStyle={{ flex: 1 }}
                ItemSeparatorComponent={Divider}
                data={flatMap(
                    data?.pages || [],
                    (retailers) => retailers.edges
                )}
                keyExtractor={(item) => item.node.id}
                onEndReached={() => {
                    if (hasNextPage) fetchNextPage();
                }}
                renderItem={({ item }: ListRenderItemInfo<Edge<Retailer>>) => (
                    <RNPList.Item
                        title={item.node.name}
                        right={() => (
                            <StyledView className="flex-1 items-center justify-center">
                                <Badge
                                    style={{
                                        backgroundColor:
                                            tailwindColors.red[500],
                                    }}
                                    visible={!Boolean(item.node.location)}
                                />
                            </StyledView>
                        )}
                        description={() => (
                            <StyledView className="mt-1">
                                <Text variant="bodyLarge">
                                    {item.node.phone_number}
                                </Text>
                                <Text variant="bodyMedium">
                                    {get(
                                        item.node.city.tags,
                                        `name_${i18n.language}`,
                                        get(item.node.city.tags, 'name')
                                    )}
                                </Text>
                            </StyledView>
                        )}
                        onPress={() => {
                            navigation.navigate('Retailers/Edit', {
                                id: item.node.id,
                            });
                        }}
                    />
                )}
            />
            <StyledFAB
                icon="plus"
                label={t('add')}
                className="absolute m-8 right-0 bottom-0 rounded-full"
                onPress={() => {
                    navigation.navigate('Retailers/Create');
                }}
            />
        </StyledView>
    );
};

export default ListView;
