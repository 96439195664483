import * as Localization from 'expo-localization';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { MMKV } from 'react-native-mmkv';

const storage = new MMKV();

const supportedLocales: any = {
    fr: {
        name: 'Français',
        translationFileLoader: () => require('./lang/fr.json'),
    },
    ar: {
        name: 'عربي',
        translationFileLoader: () => require('./lang/ar.json'),
    },
};

const languageDetector: any = {
    type: 'languageDetector',
    async: true,
    detect: (callback: any) => {
        callback(storage.getString('lang') || Localization.locale.split('-')[0]);
    },
    init: () => {},
    cacheUserLanguage: () => {},
};

const translationLoader: any = {
    type: 'backend',
    init: () => {},
    read: (language: any, namespace: any, callback: any) => {
        let resource,
            error = null;
        try {
            resource =
                supportedLocales[
                    language
                ].translationFileLoader()[namespace];
        } catch (_error) {
            error = _error;
        }

        callback(error, resource);
    },
};

i18n.use(initReactI18next).use(translationLoader).use(languageDetector).init({
    fallbackLng: 'fr',
});

export default i18n;
