import React, { FC, useEffect, useRef, useState } from 'react';
import { Searchbar, Text } from 'react-native-paper';
import { styled } from 'nativewind';
import { View } from 'react-native';
import { useMMKVString } from 'react-native-mmkv';
import Constants from 'expo-constants';
import { useTranslation } from 'react-i18next';
import maplibregl from 'maplibre-gl';
import mapStyle from '../../../../../utils/bright.json';
import 'maplibre-gl/dist/maplibre-gl.css';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import tailwindColors from 'tailwindcss/colors';
import { Image } from 'react-native';
import XarageIcon from '../../../../UI/atoms/XarageIcon';
import { get } from 'lodash';

const StyledView = styled(View);
const StyledSearchbar = styled(Searchbar);

interface Props {}

maplibregl.setRTLTextPlugin(
    'https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js',
    null as any,
    true
);

function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}

const PopupBase: FC<any> = ({ children, lngLat, map }) => {
    const popupRef = useRef<any>(null);

    useEffect(() => {
        const popup = new maplibregl.Popup()
            .setMaxWidth('fit-content')
            .setLngLat(lngLat)
            .setDOMContent(popupRef.current)
            .addTo(map);

        return () => {
            popup.remove();
        };
    }, [children, lngLat]);

    return (
        <StyledView className="hidden">
            <StyledView ref={popupRef}>{children}</StyledView>
        </StyledView>
    );
};

interface CategoryIconProps {
    active: boolean;
    icon: string;
    activeColor: string;
}

const CategoryIcon: FC<CategoryIconProps> = ({ active, activeColor, icon }) => {
    const color = get(tailwindColors, activeColor);

    return (
        <StyledView
            className="rounded-full border p-2"
            style={
                active
                    ? {
                          borderColor: color[600],
                          backgroundColor: color[500],
                      }
                    : {
                          borderColor: tailwindColors.slate[200],
                          backgroundColor: tailwindColors.slate[100],
                      }
            }>
            <XarageIcon
                name={icon}
                color={active ? 'white' : tailwindColors.slate[400]}
                size={32}
            />
        </StyledView>
    );
};

const MapView: FC<Props & NativeStackScreenProps<any>> = ({ navigation }) => {
    const { t } = useTranslation('Retailers/All', { keyPrefix: 'MapView' });
    const [content, setContent] = useState<any>(null);
    const [popupLngLat, setPopupLngLat] = useState<any>(null);
    const [token] = useMMKVString('token');
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 300);
    const mapContainer = useRef<any>(null);
    const map = useRef<any>(null);

    useEffect(() => {
        if (map.current) return;

        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: mapStyle as any,
            center: [-7.65103, 33.51102],
            zoom: 9,
            transformRequest: (url, resourceType) => {
                if (
                    resourceType === 'Source' &&
                    url.startsWith(`${Constants.manifest?.extra?.apiUrl}/api`)
                ) {
                    return {
                        url,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };
                }
            },
        });

        map.current.on('load', () => {
            map.current.addSource('retailers', {
                type: 'geojson',
                data: `${Constants.manifest?.extra?.apiUrl}/api/retailers.geojson`,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
            });

            map.current.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'retailers',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        tailwindColors.red[500],
                        100,
                        tailwindColors.red[500],
                        750,
                        tailwindColors.red[500],
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        40,
                    ],
                },
            });

            map.current.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'retailers',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': [
                        'DIN Offc Pro Medium',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': 12,
                },
            });

            map.current.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'retailers',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': tailwindColors.red[500],
                    'circle-radius': 8,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#fff',
                },
            });

            map.current.on('click', 'clusters', (e: any) => {
                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ['clusters'],
                });
                const clusterId = features[0].properties.cluster_id;

                map.current
                    .getSource('retailers')
                    .getClusterExpansionZoom(
                        clusterId,
                        (err: any, zoom: number) => {
                            if (err) return;

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom,
                            });
                        }
                    );
            });

            map.current.on('click', 'unclustered-point', function (e: any) {
                const coordinates = e.features[0].geometry.coordinates.slice();
                const name = e.features[0].properties.name;
                const phoneNumber = e.features[0].properties.phone_number;
                const images = JSON.parse(e.features[0].properties.images);
                const categories = JSON.parse(e.features[0].properties.categories);
                const id = e.features[0].properties.id;

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] +=
                        e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                setContent(
                    <StyledView className="space-y-3 items-center">
                        {Boolean(images.length) && (
                            <Image
                                source={{
                                    uri: images[0],
                                    width: 150,
                                    height: 150,
                                }}
                            />
                        )}
                        <Text
                            variant="titleMedium"
                            onPress={() => {
                                navigation.navigate('Retailers/Edit', {
                                    id,
                                });
                            }}>
                            {name}
                        </Text>
                        <Text variant="labelMedium">{phoneNumber}</Text>
                        <StyledView className="flex-column space-y-3">
                            <StyledView className="flex-row space-x-3">
                                <StyledView>
                                    <CategoryIcon
                                        icon="piston"
                                        activeColor="orange"
                                        active={categories.includes(4)}
                                    />
                                </StyledView>
                                <StyledView>
                                    <CategoryIcon
                                        icon="wrench"
                                        activeColor="orange"
                                        active={categories.includes(5)}
                                    />
                                </StyledView>
                                <StyledView>
                                    <CategoryIcon
                                        icon="scooter"
                                        activeColor="orange"
                                        active={categories.includes(6)}
                                    />
                                </StyledView>
                            </StyledView>
                            <StyledView className="flex-row space-x-3">
                                <StyledView>
                                    <CategoryIcon
                                        icon="crankset"
                                        activeColor="sky"
                                        active={categories.includes(1)}
                                    />
                                </StyledView>
                                <StyledView>
                                    <CategoryIcon
                                        icon="wrench"
                                        activeColor="sky"
                                        active={categories.includes(2)}
                                    />
                                </StyledView>
                                <StyledView>
                                    <CategoryIcon
                                        icon="bicycle"
                                        activeColor="sky"
                                        active={categories.includes(3)}
                                    />
                                </StyledView>
                            </StyledView>
                        </StyledView>
                    </StyledView>
                );
                setPopupLngLat(e.lngLat);
            });

            map.current.on('mouseenter', 'clusters', () => {
                map.current.getCanvas().style.cursor = 'pointer';
            });

            map.current.on('mouseleave', 'clusters', () => {
                map.current.getCanvas().style.cursor = '';
            });

            map.current.on('mouseenter', 'unclustered-point', () => {
                map.current.getCanvas().style.cursor = 'pointer';
            });

            map.current.on('mouseleave', 'unclustered-point', () => {
                map.current.getCanvas().style.cursor = '';
            });
        });
    }, []);

    useEffect(() => {
        if (map.current && map.current.getSource('retailers')) {
            map.current
                .getSource('retailers')
                .setData(
                    `${
                        Constants.manifest?.extra?.apiUrl
                    }/api/retailers.geojson${
                        Boolean(debouncedSearch.trim())
                            ? `?search=${debouncedSearch}`
                            : ''
                    }`
                );
        }
    }, [debouncedSearch]);

    return (
        <StyledView className="flex-1">
            <StyledView className="absolute z-10 w-full p-4">
                <StyledSearchbar
                    className="bg-white"
                    value={search}
                    placeholder={t('search')}
                    onChangeText={setSearch}
                />
            </StyledView>
            {popupLngLat && (
                <PopupBase lngLat={popupLngLat} map={map.current}>
                    {content}
                </PopupBase>
            )}
            <StyledView ref={mapContainer} className="absolute w-full h-full" />
        </StyledView>
    );
};

export default MapView;
