import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useToast } from 'react-native-toast-notifications';
import useGraphQLClient from '.';
import { City } from '../models/City';
import { Connection } from '../models/common';
import { Country } from '../models/Country';
import { Province } from '../models/Province';
import { Region } from '../models/Region';
import { Retailer } from '../models/Retailer';
import { Category } from '../models/Category';

export const useCategoriesQuery = () => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['categories'],
        async (): Promise<Category[]> => {
            const { categories } = await client.request(
                gql`
                    query {
                        categories {
                            id
                            name {
                                fr
                                ar
                            }
                        }
                    }
                `
            );

            return categories;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
        }
    );
};

export const useCountriesQuery = () => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['countries'],
        async (): Promise<Country[]> => {
            const { countries } = await client.request(
                gql`
                    query {
                        countries {
                            id
                            tags {
                                ISO3166_1
                                name
                                name_fr
                                name_ar
                                phone_code
                                flag
                            }
                        }
                    }
                `
            );

            return countries;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
        }
    );
};

export const useRegionsQuery = (country: string) => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['regions', country],
        async (): Promise<Region[]> => {
            const { regions } = await client.request(
                gql`
                    query ($countryCode: String!) {
                        regions(countryCode: $countryCode) {
                            id
                            tags {
                                ISO3166_2
                                name
                                name_fr
                                name_ar
                            }
                        }
                    }
                `,
                {
                    countryCode: country,
                }
            );

            return regions;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
            enabled: Boolean(country),
        }
    );
};

export const useProvincesQuery = (region: string) => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['provinces', region],
        async (): Promise<Province[]> => {
            const { provinces } = await client.request(
                gql`
                    query ($regionCode: String!) {
                        provinces(regionCode: $regionCode) {
                            id
                            tags {
                                ISO3166_2
                                name
                                name_fr
                                name_ar
                            }
                        }
                    }
                `,
                {
                    regionCode: region,
                }
            );

            return provinces;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
            enabled: Boolean(region),
        }
    );
};

export const useCitiesQuery = (province: string) => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['cities', province],
        async (): Promise<City[]> => {
            const { cities } = await client.request(
                gql`
                    query ($provinceCode: String!) {
                        cities(provinceCode: $provinceCode) {
                            id
                            tags {
                                name
                                place
                                name_fr
                                name_ar
                            }
                        }
                    }
                `,
                {
                    provinceCode: province,
                }
            );

            return cities;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
            enabled: Boolean(province),
        }
    );
};

export const useSuburbsQuery = (province: string, city: string) => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['suburbs', province, city],
        async (): Promise<City[]> => {
            const { suburbs } = await client.request(
                gql`
                    query ($provinceCode: String!, $cityId: String!) {
                        suburbs(provinceCode: $provinceCode, cityId: $cityId) {
                            id
                            tags {
                                name
                                name_fr
                                name_ar
                            }
                        }
                    }
                `,
                {
                    provinceCode: province,
                    cityId: city,
                }
            );

            return suburbs;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
            enabled: Boolean(city),
        }
    );
};

export const useRetailerQuery = (id: string) => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useQuery(
        ['retailer', id],
        async (): Promise<Retailer> => {
            const { retailerById } = await client.request(
                gql`
                    query ($id: ID!) {
                        retailerById(id: $id) {
                            id
                            name
                            secondary_name
                            phone_number
                            country {
                                tags {
                                    ISO3166_1
                                    phone_code
                                }
                            }
                            region {
                                tags {
                                    ISO3166_2
                                }
                            }
                            province {
                                tags {
                                    ISO3166_2
                                }
                            }
                            city {
                                id
                            }
                            suburb {
                                id
                            }
                            whatsapp
                            email
                            categories {
                                id
                            }
                            address1
                            address2
                            address3
                            zipcode
                            notes
                            images
                            owns_smartphone
                            location {
                                lat
                                lng
                            }
                            working_hours {
                                monday
                                tuesday
                                wednesday
                                thursday
                                friday
                                saturday
                                sunday
                            }
                        }
                    }
                `,
                { id }
            );

            return retailerById;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
        }
    );
};

export const useRetailersQuery = (first: number, search?: any) => {
    const toast = useToast();
    const client = useGraphQLClient();

    return useInfiniteQuery(
        ['retailers', first, search],
        async ({ pageParam }): Promise<Connection<Retailer>> => {
            const { retailers } = await client.request(
                gql`
                    query (
                        $first: Int!
                        $after: String
                        $where: QueryRetailersWhereWhereConditions
                    ) {
                        retailers(first: $first, after: $after, where: $where) {
                            pageInfo {
                                hasNextPage
                                hasPreviousPage
                                startCursor
                                endCursor
                                total
                                count
                                currentPage
                                lastPage
                            }
                            edges {
                                node {
                                    id
                                    name
                                    location {
                                        lat
                                        lng
                                    }
                                    city {
                                        tags {
                                            name
                                            name_ar
                                            name_fr
                                        }
                                    }
                                    phone_number
                                }
                                cursor
                            }
                        }
                    }
                `,
                {
                    first,
                    ...(Boolean(pageParam) ? { after: pageParam } : {}),
                    ...(Boolean(search)
                        ? {
                              where: {
                                  OR: [
                                      {
                                          column: 'NAME',
                                          operator: 'LIKE',
                                          value: `%${search}%`,
                                      },
                                      {
                                          column: 'PHONE_NUMBER',
                                          operator: 'LIKE',
                                          value: `%${search}%`,
                                      },
                                  ],
                              },
                          }
                        : {}),
                }
            );

            return retailers;
        },
        {
            onError: () => {
                toast.show("Une erreur s'est produite!", { type: 'danger' });
            },
            getNextPageParam: (lastPage) =>
                lastPage.pageInfo.hasNextPage
                    ? lastPage.pageInfo.endCursor
                    : undefined,
        }
    );
};
