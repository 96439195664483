import React, { FC } from 'react';
import { View } from 'react-native';
import {
    Button,
    TextInput,
    HelperText,
    ToggleButton,
} from 'react-native-paper';
import { styled } from 'nativewind';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';
import { FormikHelpers } from 'formik';
import { useMMKVString } from 'react-native-mmkv';
import useGraphQLClient from '../../../graphql';
import { useTranslation } from 'react-i18next';

const StyledView = styled(View);
const StyledButton = styled(Button);

interface Values {
    email: string;
    password: string;
}

interface Props {}

const LoginSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
});

const useLoginMutation = () => {
    const client = useGraphQLClient();

    return useMutation(async (data: Values): Promise<string> => {
        const {
            login: { token },
        } = await client.request(
            gql`
                mutation Login($email: String!, $password: String!) {
                    login(email: $email, password: $password) {
                        token
                    }
                }
            `,
            data
        );

        return token;
    });
};

const Login: FC<Props> = () => {
    const { t, i18n } = useTranslation('Login');
    const login = useLoginMutation();
    const [, setToken] = useMMKVString('token');
    const [, setLang] = useMMKVString('lang');

    const {
        values,
        handleChange,
        isSubmitting,
        handleSubmit,
        handleBlur,
        errors,
        touched,
    } = useFormik<Values>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
        ) => {
            login.mutate(values, {
                onSuccess: (token: string) => {
                    setSubmitting(false);
                    setToken(token);
                },
                onError: () => {
                    setSubmitting(false);
                },
            });
        },
    });

    return (
        <StyledView className="w-full h-full flex-1 justify-center items-center">
            <StyledView className="space-y-4">
                <StyledView
                    className={`${
                        i18n.dir() === 'rtl' ? 'flex-row-reverse' : 'flex-row'
                    } justify-between`}>
                    <Button
                        mode={i18n.language == 'fr' ? 'contained' : 'outlined'}
                        onPress={() => {
                            i18n.changeLanguage('fr');
                            setLang('fr');
                        }}
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            height: 46,
                        }}>
                        Francais
                    </Button>
                    <Button
                        mode={i18n.language == 'ar' ? 'contained' : 'outlined'}
                        onPress={() => {
                            i18n.changeLanguage('ar');
                            setLang('ar');
                        }}
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            height: 46,
                        }}>
                        العربية
                    </Button>
                </StyledView>
                <StyledView className="space-y-4">
                    <StyledView>
                        <TextInput
                            label={t('email')}
                            keyboardType="email-address"
                            autoCapitalize="none"
                            mode="outlined"
                            error={
                                Boolean(touched.email) && Boolean(errors.email)
                            }
                            onChangeText={handleChange('email')}
                            onBlur={handleBlur('email')}
                            value={values.email}
                        />
                        {Boolean(touched.email) && Boolean(errors.email) && (
                            <HelperText
                                type="error"
                                visible={
                                    Boolean(touched.email) &&
                                    Boolean(errors.email)
                                }>
                                {t('validation.email')}
                            </HelperText>
                        )}
                    </StyledView>
                    <StyledView>
                        <TextInput
                            label={t('password')}
                            secureTextEntry
                            autoCapitalize="none"
                            mode="outlined"
                            error={
                                Boolean(touched.password) &&
                                Boolean(errors.password)
                            }
                            onChangeText={handleChange('password')}
                            onBlur={handleBlur('password')}
                            value={values.password}
                        />
                        {Boolean(touched.password) && Boolean(errors.password) && (
                            <HelperText
                                type="error"
                                visible={
                                    Boolean(touched.password) &&
                                    Boolean(errors.password)
                                }>
                                {t('validation.password')}
                            </HelperText>
                        )}
                    </StyledView>
                    <StyledButton
                        mode="contained"
                        disabled={isSubmitting || login.isLoading}
                        loading={isSubmitting || login.isLoading}
                        style={{ borderRadius: 4, marginTop: 6 }}
                        contentStyle={{
                            height: 46,
                        }}
                        onPress={handleSubmit}>
                        {t('login')}
                    </StyledButton>
                </StyledView>
            </StyledView>
        </StyledView>
    );
};

export default Login;
