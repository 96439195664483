import Constants from 'expo-constants';
import { GraphQLClient } from 'graphql-request';
import { useMMKVString } from 'react-native-mmkv';

const ENDPOINT = `${Constants.manifest?.extra?.apiUrl}/graphql`;

const client = new GraphQLClient(ENDPOINT);

const useGraphQLClient = () => {
    const [token] = useMMKVString('token');

    client.setHeaders(
        Boolean(token)
            ? {
                  Authorization: `Bearer ${token}`,
              }
            : {}
    );

    return client;
};

export default useGraphQLClient;
