import React, { FC } from 'react';
import { View } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useTranslation } from 'react-i18next';
import ListView from './ListView';
import MapView from './MapView';
import { styled } from 'nativewind';

interface Props {}

const StyledView = styled(View);

const Tab = createMaterialTopTabNavigator();

const All: FC<Props> = () => {
    const { t, i18n } = useTranslation('Retailers/All');
    return (
        <StyledView dir="ltr" className="flex-1">
            <Tab.Navigator
                screenOptions={{ swipeEnabled: false }}
                initialRouteName="ListView">
                {i18n.dir() === 'rtl' ? (
                    <>
                        <Tab.Screen
                            name="MapView"
                            component={MapView}
                            options={{ title: t('map') }}
                        />
                        <Tab.Screen
                            name="ListView"
                            component={ListView}
                            options={{ title: t('list') }}
                        />
                    </>
                ) : (
                    <>
                        <Tab.Screen
                            name="ListView"
                            component={ListView}
                            options={{ title: t('list') }}
                        />
                        <Tab.Screen
                            name="MapView"
                            component={MapView}
                            options={{ title: t('map') }}
                        />
                    </>
                )}
            </Tab.Navigator>
        </StyledView>
    );
};

export default All;
